import React from 'react'
import styled, { css, x } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Container, Row, Col } from '@atoms/Grid'
import { Text, H1 } from '@atoms/Typography'
import { Button } from '@atoms/Button'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'

const Hero = ({ block, data, onScrollToJobs }) => {
  const { title, paragraphs, button, sideImage } = block
  const { careers } = data
  const totalCareers = careers && careers.nodes ? careers.nodes.length : 0

  return (
    <x.div mb={{ xs: 150, xl: 300 }}>
      <Container>
        <Row alignItems={{ md: 'center' }}>
          <Col col={{ xs: 1, md: 1 / 2, xl: 5 / 12 }}>
            <H1 as="h1" mb={{ xs: 20 }}>
              {title}
            </H1>
            <S.HTMLContent
              dangerouslySetInnerHTML={{
                __html: transformMDtoHTML(paragraphs),
              }}
            />
            <S.Button variant="yellow" onClick={onScrollToJobs}>
              {`${button} (${totalCareers})`}
            </S.Button>
          </Col>
          <Col col={{ xs: 1, md: 1 / 2 }} ml={{ xl: `${(1 / 12) * 100}%` }}>
            <GatsbyImage
              image={sideImage.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </Col>
        </Row>
      </Container>
    </x.div>
  )
}

export default Hero

const S = {}

S.Container = styled.sectionBox`
  display: flex;
  ${breakpoints({
    xs: css`
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `,
  })}
`

S.Title = styled(Text)`
  font-weight: ${th.fontWeight('black')};
  ${breakpoints({
    xs: css`
      font-size: 32;
      margin-bottom: 20;
    `,
    md: css`
      font-size: 56;
      line-height: 1.4;
      margin-bottom: 15;
    `,
  })}
`

S.HTMLContent = styled(HTMLContent)`
  margin-bottom: 30;
`

S.Button = styled(Button)`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 0;
    `,
  })}
`
