import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import idgen from '@utils/idgen'
import Layout from '@organisms/Layout'
import Hero from '@organisms/Careers/Hero'
import ExternalSection from '@organisms/Careers/ExternalSection'
import { Container } from '@atoms/Grid'

const Careers = ({ data }) => {
  const { blocks, meta } = data.careersPage.nodes[0].frontmatter
  const externalSectionRef = useRef(null)

  const onScrollToJobs = () => {
    externalSectionRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <Layout
      hamburgerMenu
      title={meta.title}
      description={meta.description}
      invertColor
    >
      <S.Main>
        {blocks.map((item) => {
          switch (item.type) {
            case 'hero':
              return (
                <Hero
                  block={item}
                  data={data}
                  key={idgen()}
                  onScrollToJobs={onScrollToJobs}
                />
              )
            case 'externalSection':
              return (
                <ExternalSection
                  block={item}
                  data={data}
                  key={idgen()}
                  ref={externalSectionRef}
                />
              )
            default:
              return null
          }
        })}
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};
  padding-top: 160;
  padding-bottom: 340;
`

export default Careers

// Set here the ID of the home page.
export const pageQuery = graphql`
  query CareerPage {
    careersPage: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "careers" } } }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
    careers: allMarkdownRemark(
      filter: {
        frontmatter: {
          collection: { eq: "careers" }
          status: { eq: "published" }
        }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        html
        frontmatter {
          slug
          location
          ...RepeaterFragment
        }
      }
    }
  }
`
