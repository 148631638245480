import React from 'react'
import { lastIndexOf } from 'lodash'
import { x } from '@xstyled/styled-components'
import idgen from '@utils/idgen'
import Job from './Job'

const Jobs = ({ jobs }) => {
  const lastIndex = lastIndexOf(jobs) - 1
  return (
    <x.div>
      {jobs.map((job, index) => (
        <Job
          noLine={index === lastIndex}
          key={idgen()}
          title={job.frontmatter.title}
          slug={job.frontmatter.slug}
          location={job.frontmatter.location}
        />
      ))}
    </x.div>
  )
}

export default Jobs
