import React from 'react'
import styled, { css, breakpoints } from '@xstyled/styled-components'
import { th } from '@xstyled/system'

const ListItem = ({ invertColor, noLine, ...props }) => (
  <Item invertColor={invertColor} noLine={noLine} {...props} />
)

const Item = styled.div`
  border-bottom: ${({ noLine }) => (noLine ? '0' : '1px')} solid
    ${({ invertColor }) =>
      invertColor ? th('colors.greyInvert') : th('colors.grey')};
  ${({ noLine }) =>
    noLine
      ? css`
          padding: 10 0;
        `
      : css`
          padding: 20 0;
        `}
  font-weight: ${th('fontWeights.medium')};
  ${breakpoints({
    xs: css`
      font-size: 16;
    `,
    md: css`
      font-size: 18;
    `,
  })}
`

export default ListItem
