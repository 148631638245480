import React from 'react'
import styled, { css, x } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import ListItem from '@atoms/ListItem'
import arrowRight from '@assets/svg/arrow-right.svg'
import { Link } from 'gatsby'

const Job = ({ noLine, title, location, slug }) => {
  return (
    <S.ListItemLink to={`/careers/${slug}/`}>
      <S.ListItem noLine={noLine} invertColor>
        <x.div as="h3">
          <S.JobTitle>{title}</S.JobTitle>
          <S.JobLocation>{location}</S.JobLocation>
        </x.div>
        <S.ListItemArrow />
      </S.ListItem>
    </S.ListItemLink>
  )
}

const S = {}

S.ListItem = styled(ListItem)`
  font-size: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30;
  padding-top: 30;
`

S.ListItemLink = styled(Link)`
  width: 100%;

  &:link,
  &:visited {
    text-decoration: inherit;
    color: inherit;
  }

  &:last-of-type {
    ${S.ListItem} {
      padding-bottom: 0;
    }
  }

  ${breakpoints({
    xs: css`
      &:first-of-type {
        ${S.ListItem} {
          padding-top: 50;
        }
      }
    `,
    md: css`
      &:first-of-type {
        ${S.ListItem} {
          padding-top: 0;
        }
      }
    `,
  })}
`

S.JobTitle = styled.pBox`
  ${breakpoints({
    xs: css`
      max-width: 259;
      line-height: 1.4;
      font-size: 20;
    `,
    md: css`
      max-width: 588;
      font-size: 32;
    `,
  })}
`

S.JobLocation = styled.pBox`
  font-weight: ${th('fontWeights.normal')};
  line-height: 1.4;
  ${breakpoints({
    xs: css`
      font-size: 14;
    `,
    md: css`
      font-size: 19;
    `,
  })}
`

S.ListItemArrow = styled(arrowRight)`
  ${breakpoints({
    xl: css`
      display: none;
    `,
  })}
`

export default Job
