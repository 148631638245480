import React, { forwardRef } from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'

import { Container, Row, Col } from '@atoms/Grid'
import { H3 } from '@atoms/Typography'
import Jobs from './Jobs'

const ExternalSection = forwardRef(({ block, data }, ref) => {
  const { title } = block
  const { careers } = data
  const jobs = careers.nodes || []
  const totalJobs = jobs.length

  return (
    <Container ref={ref}>
      <Row>
        <Col col={{ xs: 1, xl: 5 / 12 }}>
          <H3 mb={{ xs: 20, md: 50 }}>
            {title}
            {` (${totalJobs})`}
          </H3>
        </Col>

        <Col col={{ xs: 1, xl: 1 / 2 }} ml={{ xl: `${(1 / 12) * 100}%` }}>
          <Jobs jobs={jobs} />
        </Col>
      </Row>
    </Container>
  )
})

const S = {}

S.Container = styled.sectionBox`
  display: flex;
  ${breakpoints({
    xs: css`
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
      justify-content: space-between;
    `,
  })}
`

export default ExternalSection
